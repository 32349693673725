@media screen and (min-width: 740px) {
  .container {
    max-width: 700px;
  }
}

.container {
  color: white;
  font-family: 'Colfax', sans-serif;
  max-width: 400px;
}
