@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: 'Colfax';
  src: url('../fonts/Colfax-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: auto;
}

.font-colfax {
  font-family: 'Colfax', sans-serif;
}

.font-arial {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: 'Colfax', sans-serif;
}
