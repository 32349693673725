.amountInput {
  line-height: 32px;
  font-size: 20px;
  line-height: 32px;
}

.transferBtn {
  border: 1px solid #00ffad;
  color: #00ffad;
}

.listingModalBg {
  opacity: 60%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #222;
  z-index: 4;
  max-height: 100%;
}

.listingModal {
  top: 50%; /* Position Y halfway in */
  left: 50%; /* Position X halfway in */
  transform: translate(-50%, -50%);
  z-index: 5;
}

.listingModalImage {
  width: 65px;
}

.listingModalPrice {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.listingModalCnt {
  width: 360px;
  min-height: 340px;
}

.listingModalNextBtn {
  padding-top: 23px;
  padding-bottom: 23px;
}
