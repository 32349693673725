.modalBg {
  opacity: 60%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #222;
  z-index: 4;
  max-height: 100%;
}

.modal {
  top: 50%; /* Position Y halfway in */
  left: 50%; /* Position X halfway in */
  transform: translate(-50%, -50%);
  z-index: 5;
}

.modalImage {
  width: 65px;
}

.modalCnt {
  width: 380px;
  min-height: 340px;
}
