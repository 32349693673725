html,
#root {
  overflow-x: hidden;
  height: 100%;
}

body {
  overflow-wrap: break-word;
  font-family: 'Colfax', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

#layout-container {
  scrollbar-color: #00ffad #27272a;
  scrollbar-width: thin;
}

.black-scroll-bg {
  scrollbar-color: #00ffad #000000 !important;
}

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s 0.4s both;
  animation: jello-horizontal 0.9s 0.4s both;
}
.slide-fwd-center {
  -webkit-animation: slide-fwd-center 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-fwd-center 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media screen and (min-width: 740px) {
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.pulse-box {
  -webkit-animation-name: pulseBox;
  -moz-animation-name: pulseBox;
  -o-animation-name: pulseBox;
  animation-name: pulseBox;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  border: 0px;
}

@-webkit-keyframes pulseBox {
  0% {
    -webkit-box-shadow: 0px 0px 50px 5px rgb(255 0 0 / 60%);
  }
  100% {
    -webkit-box-shadow: 0px 0px 60px 10px rgb(255 0 0 / 90%);
  }
}
@-moz-keyframes pulseBox {
  0% {
    -moz-box-shadow: 0px 0px 50px 5px rgb(255 0 0 / 60%);
  }
  100% {
    -moz-box-shadow: 0px 0px 60px 10px rgb(255 0 0 / 90%);
  }
}
@-o-keyframes pulseBox {
  0% {
    -o-box-shadow: 0px 0px 50px 5px rgb(255 0 0 / 60%);
  }
  100% {
    -o-box-shadow: 0px 0px 60px 10px rgb(255 0 0 / 90%);
  }
}
@keyframes pulseBox {
  0% {
    box-shadow: 0px 0px 50px 5px rgb(255 0 0 / 60%);
  }
  100% {
    box-shadow: 0px 0px 60px 10px rgb(255 0 0 / 90%);
  }
}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
  animation: pulsate-fwd 2s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.list-checkmark {
  filter: brightness(0) saturate(100%) invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}

.wallet-adapter-button.connector-btn:hover {
  background: #00ffad;
}
