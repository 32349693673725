.sendModal {
  position: fixed;
  top: 50vh; /* Position Y halfway in */
  left: 50%; /* Position X halfway in */
  transform: translate(-50%, -50%);
  z-index: 5;
}

.sendModalBg {
  opacity: 60%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #222;
  z-index: 4;
  max-height: 100%;
}

.amountInput {
  line-height: 20px;
  font-size: 20px;
  line-height: 20px;
  padding-top: 14px;
  padding-bottom: 12px;
}

@media screen and (max-width: 491px) {
  .sendModal {
    left: 10%;
    transform: translate(-5%, -50%);
  }
}

@media screen and (min-width: 492px) and (max-width: 650px) {
  .sendModal {
    left: 30%;
    transform: translate(-22%, -50%);
  }
}
